import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./Footer.scss";

function Footer(props) {
  const location = useLocation();

  const links = {
    links: [
      { linkName: "Home", path: "/home", id: 1 },
      { linkName: "Impressum", path: "/legal", id: 2 },
      { linkName: "Datenschutz", path: "/legal", id: 3 },
      { linkName: "Gemälde des Tages", path: "/painting/7612", id: 4 },
    ],
  };

  const activeLinks = links.links.filter((link) => {
    return link;
  });

  const listItems = activeLinks.map((link) => (
    <li key={link.id}>
      <Link to={link.path}>{link.linkName}</Link>
    </li>
  ));

  return (
    <footer className={`footer ${props.className}`}>
      <ul>
        {listItems}
        <div>
          <a href="https://github.com/silaskitschke/tempera" className="Svg-Link">
            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                fill="white"
                d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"
              />
            </svg>
          </a>
          <a href="mailto:mail@tempera.info" className="Svg-Link">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path fill="white" d="M12 .02c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.99 6.98l-6.99 5.666-6.991-5.666h13.981zm.01 10h-14v-8.505l7 5.673 7-5.672v8.504z" />
            </svg>
          </a>
        </div>
      </ul>
      <div className="Mail-Footer">
        <h2>mail@tempera.info</h2>
        <p>Get in Touch.</p>
      </div>
      <div className="left-side">
        <Link to="/">
          <svg viewBox="0 0 457.29 58.8" height="40px">
            <path
              fill="white"
              d="M18.82,11.09H0V0H51.24V11.09h-18.82V58.8h-13.61V11.09Zm43.93-.17V0h45.53V58.8H63.84v-10.92h30.91v-12.77h-27.3v-10.58h27.3V10.92H62.75Zm114.07,47.88l-.08-35.28-17.3,29.06h-6.13l-17.22-28.31V58.8h-12.77V0h11.26l22.01,36.54L178.25,0h11.17l.17,58.8h-12.77ZM246.75,2.6c3.83,1.74,6.79,4.2,8.86,7.39,2.07,3.19,3.11,6.97,3.11,11.34s-1.04,8.08-3.11,11.3c-2.07,3.22-5.03,5.68-8.86,7.39-3.84,1.71-8.36,2.56-13.57,2.56h-11.84v16.21h-13.61V0h25.45c5.21,0,9.73,.87,13.57,2.6Zm-5,26.25c2.13-1.76,3.19-4.27,3.19-7.52s-1.06-5.84-3.19-7.6c-2.13-1.76-5.24-2.65-9.32-2.65h-11.09V31.5h11.09c4.09,0,7.2-.88,9.32-2.65Zm76.36,19.03v10.92h-45.53V0h44.44V10.92h-30.91v12.77h27.3v10.58h-27.3v13.61h32Zm52.5,10.92l-11.34-16.38h-12.52v16.38h-13.61V0h25.45c5.21,0,9.73,.87,13.57,2.6,3.83,1.74,6.79,4.2,8.86,7.39,2.07,3.19,3.11,6.97,3.11,11.34s-1.05,8.13-3.15,11.3c-2.1,3.16-5.08,5.59-8.95,7.27l13.19,18.9h-14.62Zm-3.44-45.07c-2.13-1.76-5.24-2.65-9.32-2.65h-11.09V31.58h11.09c4.09,0,7.2-.9,9.32-2.69,2.13-1.79,3.19-4.31,3.19-7.56s-1.06-5.84-3.19-7.6Zm70.64,32.47h-27.3l-5.21,12.6h-13.94L417.56,0h13.44l26.29,58.8h-14.28l-5.21-12.6Zm-4.28-10.33l-9.32-22.51-9.32,22.51h18.65Z"
            />
          </svg>
        </Link>

        <div>© 2022 Alle Rechte vorbehalten</div>
      </div>
    </footer>
  );
}

export default Footer;
